<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">首页弹窗配置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="弹窗名称" class="searchboxItem ci-full">
              <span class="itemLabel">弹窗名称:</span>
              <el-input
                v-model="popupWindowName"
                size="small"
                placeholder="请输入弹窗名称"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList3"
                clearable
                filterable
                placeholder="请选择行政区划"
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
            <div title="使用状态" class="searchboxItem ci-full">
              <span class="itemLabel">使用状态:</span>
              <el-select
                v-model="UsageStatus"
                placeholder="请选择使用状态"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addclassSubject()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="弹窗名称"
                align="center"
                show-overflow-tooltip
                prop="popupWindowName"
              />
              <el-table-column
                label="弹窗说明"
                align="center"
                show-overflow-tooltip
                prop="popupWindowDescription"
              />
              <el-table-column
                label="行政区划"
                align="center"
                show-overflow-tooltip
                prop="areaRangeString"
              >
                <template slot-scope="scope">
                  {{scope.row.areaRangeString || '--'}}
                </template>
              </el-table-column>
              <el-table-column
                label="机构名称"
                align="center"
                show-overflow-tooltip
                prop="compName"
              >
               <template slot-scope="scope">
                  {{scope.row.compName || '--'}}
                </template>
              </el-table-column>
              <el-table-column
                label="班级编码"
                align="center"
                show-overflow-tooltip
                prop="projectCode"
              >
               <template slot-scope="scope">
                  {{scope.row.projectCode || '--'}}
                </template>
              </el-table-column>
              <el-table-column
                label="使用状态"
                align="center"
                show-overflow-tooltip
                prop="state"
              >
                <template slot-scope="scope">
                  {{ scope.row.state ? "启用" : "禁用" }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                show-overflow-tooltip
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row.popupWindowId)"
                    >编辑</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
        <el-dialog
          :title="title"
          :visible.sync="DialogVisible"
          width="45%"
          center
          top="3%"
          @close="doClose"
        >
          <div style="height:550px" class="ovy-a">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="8rem"
              class="demo-ruleForm"
              style="width: 90%"
            >
              <el-form-item label="弹窗名称：" prop="popupWindowName">
                <el-input
                  v-model="ruleForm.popupWindowName"
                  size="small"
                  placeholder="请输入弹框名称"
                  maxlength="20"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="弹窗说明：" prop="popupWindowDescription">
                <el-input
                  v-model="ruleForm.popupWindowDescription"
                  placeholder="请输入弹窗说明"
                  size="small"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="弹窗范围：">
                <el-select
                  v-model="ruleForm.rangeType"
                  placeholder="请选择弹窗范围："
                  size="small"
                >
                  <el-option
                    v-for="item in popupsRangeTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="行政区划："
                prop="areaRange"
                v-if="ruleForm.rangeType == '10'"
              >
                <el-cascader
                  v-model="ruleForm.areaRange"
                  ref="cascader"
                  placeholder="请选择行政区划"
                  :options="areatreeList2"
                  :props="propsarea2"
                  :collapse-tags="true"
                  size="small"
                  @change="selectHandle"
                >
                  <template slot-scope="{ node, data }">
                    <span>{{ data.label }}</span>
                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                  </template>
                </el-cascader>
              </el-form-item>
              <el-form-item
                label="指定人群："
                prop="popupUserUrl"
                :rules="{
                required: stu == 'edit'?false:true,
                message: '请上传指定人群文件',
                trigger: 'change',
              }"
                v-if="ruleForm.rangeType == '20'"
              >
                <div style="display:flex;align-items:center">
                  <el-button type="text" @click="downloadExcel" style="margin-right:15px"
                    >下载模板</el-button
                  >
                  <el-upload
                    v-model="ruleForm.fileName"
                    v-if="!ruleForm.fileName"
                    class="upload-demo upload-btn questionbank_button"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button size="small" class="bgc-bv "
                      >导入推送学员</el-button
                    >
                  </el-upload>
                  <span v-else style="color:#f46173;margin-left: 20px;">
                    {{ ruleForm.fileName }}
                    <a @click="reomveExl" style="color: #5bb5ff;margin-left:5px"
                      >删除</a
                    >
                  </span>
                </div>
              </el-form-item>
              <el-form-item
                label="班级编码："
                prop="projectId"
                v-if="ruleForm.rangeType == '30'"
              >
                <el-select
                  size="small"
                  v-model="ruleForm.projectId"
                  remote
                  :remote-method="superPorjectSelect"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.projectId"
                    :label="item.projectCode"
                    :value="item.projectId"
                  >
                    <span style="float: left">{{ item.projectName }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.projectCode }}</span
                    ></el-option
                  >
                </el-select>
              </el-form-item>
              <el-form-item
                label="机构名称："
                prop="compId"
                v-if="ruleForm.rangeType == '40'"
              >
                <el-select
                  size="small"
                  v-model="ruleForm.compId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in CompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="使用状态：" prop="state">
                <el-select
                  v-model="ruleForm.state"
                  placeholder="请选择使用条件"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="弹出次数：" prop="popupType">
                <el-select
                  v-model="ruleForm.popupType"
                  placeholder="请选择弹出次数"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in dialogPopup"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="上传图片：" prop="popupWindowShowImage">
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.popupWindowShowImage ||
                        require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label="按钮功能：" prop="operationType">
                <el-select
                  v-model="ruleForm.operationType"
                  placeholder="请选择按钮功能"
                  size="small"
                  clearable
                  @change="dialogButtonFunction"
                >
                  <el-option
                    v-for="item in dialogButton"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="ruleForm.operationType == '20'"
                label="链接网址："
                prop="path"
              >
                <el-input v-model="ruleForm.path" size="small"></el-input>
              </el-form-item>
              <el-form-item
                v-if="ruleForm.operationType == '30'"
                label="应用功能："
                prop="innerPath"
              >
                <el-select
                  v-model="ruleForm.innerPath"
                  placeholder="请选择应用功能"
                  size="small"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in innerList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="
                  ruleForm.operationType == '40' ||
                    ruleForm.operationType == '50'
                "
                label="小程序地址："
                prop="Appletspath"
              >
                <el-input
                  v-model="ruleForm.Appletspath"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-form>
            <div style="display: flex; justify-content: center">
              <el-button @click="doClose">取消</el-button>
              <el-button class="bgc-bv" @click="submitForm">确定</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/classParams",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    //验证码校验
    let validateUrl = (rule, value, callback) => {
      // let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
        let reg1 =/([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|[wW]{3}.|[wW][aA][pP].|[fF][tT][pP].|[fF][iI][lL][eE].)[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+[-A-Za-z0-9+&@#\/%=~_|]/

      if (!value) {
        callback(new Error("请输入链接地址"));
      } else if (value && !reg1.test(value)) {
        callback(new Error("请输入带有http或https的正确链接地址"));
      } else {
        callback();
      }
    };
    return {
      preSelected: [], // 上次选中的数据
      originData: [], //  源数据平铺成一级节点
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      popupWindowName: "",
      areaId: "",
      UsageStatus: "",
      areatreeList2: [],
      areatreeList3: [],
      propsarea2: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
        multiple: true,
      },
      options: [
        {
          value: true,
          label: "启用",
        },
        {
          value: false,
          label: "禁用",
        },
      ],
      //弹出次数数据
      dialogPopup: [
        {
          value: "10",
          label: "每次打开App",
        },
        {
          value: "20",
          label: "只弹1次",
        },
      ],
      //按钮功能数据
      dialogButton: [],
      DialogVisible: false,
      title: "新增",
      ruleForm: {
        popupWindowImage: "",
        popupWindowShowImage: "",
        popupWindowName: "", //弹窗名称
        popupWindowDescription: "", //弹窗说明
        rangeType: null, //弹窗范围
        areaRange: "", //行政区划
        projectId: "", //班级编码
        compId: "", //机构名称
        popupUserUrl: "", //特定人群
        path: "",
        innerPath: "",
        state: true, //使用状态
        popupType: "", //弹出次数
        operationType: "10", //按钮功能
        imageWidth: "",
        imageHeight: "",
        Appletspath: "",
      },
      rules: {
        popupWindowName: [
          { required: true, message: "请输入弹窗名称", trigger: "blur" },
        ],
        popupWindowDescription: [
          { required: true, message: "请输入弹窗说明", trigger: "blur" },
        ],
        rangeType: [
          { required: true, message: "请选择弹窗范围", trigger: "change" },
        ],
        areaRange: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        projectId: [
          { required: true, message: "请选择班级编码", trigger: "change" },
        ],
        compId: [{ required: true, message: "请选择机构", trigger: "change" }],
        // popupUserUrl: [
        //   { required: true, message: "请上传指定人群文件", trigger: "change" },
        // ],
        state: [
          {
            required: true,
            message: "请选择使用状态",
            trigger: ["blur", "change"],
          },
        ],
        popupType: [
          {
            required: true,
            message: "请选择弹出次数",
            trigger: ["blur", "change"],
          },
        ],
        popupWindowShowImage: [
          {
            required: true,
            message: "请上传图片",
            trigger: "change",
          },
        ],
        operationType: [
          {
            required: true,
            message: "请选择按钮功能",
            trigger: "change",
          },
        ],
        path: [{ required: true, validator: validateUrl, trigger: "blur" }],
        innerPath: [
          {
            required: true,
            message: "请选择应用功能",
            trigger: ["blur", "change"],
          },
        ],
        Appletspath: [
          {
            required: true,
            message: "请输入小程序地址",
            trigger: "blur",
          },
        ],
      },
      innerList: [],
      popupsRangeTypeList: [], //弹窗范围
      projectList: [], //班级
      CompanyList: [], //机构
    };
  },
  created() {},
  mounted() {
    this.getTableHeight();
    this.getareatree2();
    this.getDictionary();
    this.getpopupsRangeTypeList();
  },
  methods: {
    judgetAllSelected(node) {
      // 判断是否是全选,也就是看已选择的选中中包不包含"全选"
      let isAllSelected = false
      for(let i = 0; i < node.length; i++) {
          if(node[i] === '全选') {
            isAllSelected = true
            break;
          }
      }
      return isAllSelected
    },
    loopFlatData(list = [], parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopFlatData(e.children, pNode)
        }else {
          if(e.label !== '全选') {
            if(parentNode.length > 0) {
              pNode.push(e.value)
              this.originData.push({ ...e, parentNode: pNode })
            }else {
              this.originData.push(e)
            }
          }
        }
      })
    },
    loopSelectData(list, parentNode = []) {
      list.length > 0 && list.forEach(e => {
        let pNode = [...parentNode]; // 注意这里必须是深拷贝，否则会由于引用类型赋值的是地址（指针），导致parentNode在pNode更新时，同时被更新
        if(e.children && e.children.length > 0) {
          pNode.push(e.value)// 1 11
          this.loopSelectData(e.children, pNode)
        }else {
          if(parentNode.length > 0) {
            this.ruleForm.areaRange.push(...parentNode, e.value)
          }else {
            this.ruleForm.areaRange.push(e.value)
          }
        }
      })
    },
    checkIsAddAllSelected() {
      let list = this.areatreeList2; // 原始数据列表
      if(this.originData.length === 0) {
        this.loopFlatData(list) // 把所有的父子级平铺成一个一级列表
      }
      let origin = this.originData;
      let now = [...this.ruleForm.areaRange].filter(item => item !== '全选')
      if(origin.length > now.length) {
        // 非全选时, 如果有之前选过全选,要把全选过滤掉
        // let index = this.ruleForm.areaRange.findIndex(e=> e === '全选')
        // if(index != -1){
        //   node.shift()
        //   this.ruleForm.areaRange = node
        // }
        this.ruleForm.areaRange = this.ruleForm.areaRange.filter(item => item !== '全选')
      }else {
        // 当所有的数据都选择时, 要自动把全选勾选上
        if(this.ruleForm.areaRange[0] && this.ruleForm.areaRange[0] !== '全选') {
          this.ruleForm.areaRange = ['全选',...this.ruleForm.areaRange]
        }
      }
    },
    selectHandle(node = []) {
      this.ruleForm.areaRange = []
      let list = this.areatreeList2
      let current = []; // 获取当前选中的option
      if(node.length >= this.preSelected.length) {
        current = node.filter(item => !this.preSelected.includes(item))
      }else {
        // 取消选中
        current = this.preSelected.filter(item => !node.includes(item))
      }
      const currentValue = current.length > 0 ? current[0] || '' : ''
      if(currentValue === '全选') {
        if(this.judgetAllSelected(node)) {
          this.loopSelectData(list)
        }else {
          this.ruleForm.areaRange = []
        }
      }else {
        this.ruleForm.areaRange = node
      }
      // this.checkIsAddAllSelected(); // 主要是勾选或取消非“全选”项时，对于全选的逻辑处理
      this.preSelected = this.ruleForm.areaRange; // 保存上一次的选择结果
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        state: this.UsageStatus,
        // projectName: this.projectName || "",
      };
      if (this.popupWindowName) {
        params.popupWindowName = this.popupWindowName;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      this.doFetch({
        url: "/biz/homepage/edu-homepage-popup-window/pageList",
        params,
        pageNum,
      });
    },
    getareatree2() {
      this.$post("/sys/area/tree?areaLevel=2").then((ret) => {
        let data = ret.data || [];
        this.areatreeList2 =
          [{ label: '全选', value: '全选' }, ...data] || [];
        this.areatreeList3 = [...data] || [];
      });
    },
    //应用管理数据
    getDictionary() {
      this.$post("/sys/shortcut/v1/query/built-in")
        .then((ret) => {
          this.innerList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    //上传图片获取宽高
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function(event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function() {
          that.ruleForm.imageWidth = img.width;
          that.ruleForm.imageHeight = img.height;
        };
      };
      reader.readAsDataURL(file);
      return isLt2M;
    },
    handleAvatarSuccess2(res) {
      ;
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.popupWindowShowImage = result.data.fileURL;
            this.ruleForm.popupWindowImage = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    getpopupsRangeTypeList() {
      //弹框范围
      const popupsRangeTypeList = this.$setDictionary(
        "POPUPS_RANGE_TYPE",
        "list"
      );
      // 按钮功能
      const popupsOperationTypeList = this.$setDictionary(
        "POPUPS_OPERATION_TYPE",
        "list"
      );
      const list = [];
      const list2 = [];
      for (const key in popupsRangeTypeList) {
        list.push({
          value: key,
          label: popupsRangeTypeList[key],
        });
      }
      for (const key in popupsOperationTypeList) {
        if (key != "60") {
          list2.push({
            value: key,
            label: popupsOperationTypeList[key],
          });
        }
      }
      this.popupsRangeTypeList = [{label:'全部',value:null} , ...list];
      this.dialogButton = list2;
    },
    // 机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    //班级编码
    superPorjectSelect(query) {
      if (query.trim().length >= 2) {
        this.$post(
          "/biz/project/superSelectProject",
          {
            pageNum: 1, //每次都只要第一页
            pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
            projectName: query,
          },
          3000,
          false
        ).then((res) => {
          if (res.data.list.length === 0) {
            this.projectList = [{}];
          } else {
            this.projectList = res.data.list;
          }
        });
      } else {
        this.CompanyList = [];
      }
    },
    /**
     *班级编码回显接口
     */
    getProjectId(id) {
      this.$post("/biz/project/query", { projectId: id }).then((res) => {
        this.projectList = [res.data];
      });
    },
    //下载模版
    /* 下载模版 */
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/popus_user.xls";
      link.setAttribute("download", "popus_user.xls");
      document.body.appendChild(link);
      link.click();
    },
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.popupUserUrl = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.popupUserUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    doClose() {
      this.DialogVisible = false;
      this.ruleForm = {
          popupWindowImage: "",
        popupWindowShowImage: "",
        popupWindowName: "", //弹窗名称
        popupWindowDescription: "", //弹窗说明
        rangeType: null, //弹窗范围
        areaRange: "", //行政区划
        projectId: "", //班级编码
        compId: "", //机构名称
        popupUserUrl: "", //特定人群
        path: "",
        innerPath: "",
        state: true, //使用状态
        popupType: "", //弹出次数
        operationType: "10", //按钮功能
        imageWidth: "",
        imageHeight: "",
        Appletspath: "",
      };
      this.$refs.ruleForm.resetFields();
      this.preSelected = []
      // this.ruleForm.path = "";
      // this.ruleForm.innerPath = "";
      // this.ruleForm.Appletspath = "";
    },
    submitForm() {
      if (this.ruleForm.operationType == "20") {
        // this.$refs.ruleForm.clearValidate("dispCondition");
        this.$refs.ruleForm.clearValidate("innerPath");
      } else {
        this.$refs.ruleForm.clearValidate("path");
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let params = {
        popupWindowName: this.ruleForm.popupWindowName,
        popupWindowDescription: this.ruleForm.popupWindowDescription,
        state: this.ruleForm.state,
        popupType: this.ruleForm.popupType,
        popupWindowImage: this.ruleForm.popupWindowImage,
        operationType: this.ruleForm.operationType,
        imageWidth: this.ruleForm.imageWidth,
        imageHeight: this.ruleForm.imageHeight,
        rangeType: this.ruleForm.rangeType,
      };
      if (this.ruleForm.operationType == "20") {
        params.operationObject = this.ruleForm.path;
      } else if (this.ruleForm.operationType == "30") {
        // params.dispCondition = this.ruleForm.dispCondition;
        params.operationObject = this.ruleForm.innerPath;
      }
      if (
        this.ruleForm.operationType == "40" ||
        this.ruleForm.operationType == "50"
      ) {
        params.operationObject = this.ruleForm.Appletspath;
      }
      if (this.ruleForm.rangeType == "10") {
        // 将全选过滤出去
        params.areaRange = this.ruleForm.areaRange.filter(e=>e!='全选').toString();
        if(params.areaRange==''){
          this.$message.error('请选择行政区划！')
          return
        }
      }
      if (this.ruleForm.rangeType == "20") {
        params.popupUserUrl = this.ruleForm.popupUserUrl;
      }
      if (this.ruleForm.rangeType == "30") {
        params.projectId = this.ruleForm.projectId;
      }
      if (this.ruleForm.rangeType == "40") {
        params.compId = this.ruleForm.compId;
      }
      let api =
        this.stu != "add"
          ? "/biz/homepage/edu-homepage-popup-window/modify"
          : "/biz/homepage/edu-homepage-popup-window/insert";
      if (this.stu != "add") {
        params.popupWindowId = this.popupWindowId;
      }
      this.$post(api, params).then((res) => {
        // ;
        if (res.status == "0") {
          this.getData();
          // this.DialogVisible = false;
          this.doClose();
        }
      });
    },
    dialogButtonFunction(val) {
      if (val == "10") {
        this.ruleForm.path = "";
        this.ruleForm.innerPath = "";
        this.ruleForm.Appletspath = "";
        this.$refs.ruleForm.clearValidate("innerPath");
        this.$refs.ruleForm.clearValidate("path");
        this.$refs.ruleForm.clearValidate("Appletspath");
      } else if (val == "20") {
        this.$refs.ruleForm.clearValidate("innerPath");
      } else if (val == "30") {
        this.$refs.ruleForm.clearValidate("path");
      } else if (val == "40" || val == "50") {
        this.$refs.ruleForm.clearValidate("Appletspath");
      }
    },
    //编辑回显数据
    handleEdit(popupWindowId) {
      this.DialogVisible = true;
      this.title = "修改";
      this.popupWindowId = popupWindowId;
      this.stu = "edit";
      this.$post("/biz/homepage/edu-homepage-popup-window/getInfo", {
        popupWindowId,
      })
        .then((ret) => {
          let data = ret.data || {};
          if (data.operationType == "20") {
            this.ruleForm.path = data.operationObject;
          } else if (data.operationType == "30") {
            this.ruleForm.innerPath = data.operationObject;
          } else if (data.operationType == "40" || data.operationType == "50") {
            this.ruleForm.Appletspath = data.operationObject
          }
          if (data.rangeType === undefined) {
            data.rangeType = null
          }
          if (data.rangeType == "30") {
            this.getProjectId(data.projectId);
          }
          if (data.rangeType == "40") {
            this.getCompany(data.compId);
          }
          this.ruleForm = {
            ...this.ruleForm,
            ...data,
          };
            if (data.rangeType == "10") {
            this.ruleForm.areaRange = data.areaRange.split(",");
            if(this.ruleForm.areaRange.includes('all')){
              this.selectHandle(['全选'])
            }
          }
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    addclassSubject() {
      this.DialogVisible = true;
      this.title = "新增";
      this.stu = "add";
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.upload-btn {
  height: 32px;
  .el-upload {
    height: 32px !important;
    border: none !important;
  }
}
</style>
